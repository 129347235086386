import React, {useEffect} from 'react';
import { Box } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import CommentSection from './components/CommentSection';
import NotFoundPage from './components/NotFoundPage'; // Import the NotFoundPage component
import 'katex/dist/katex.min.css'; // if you're using rehype-katex
// import 'highlight.js/styles/github.css'; // Import your preferred style
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai.css'; // Import the Monokai theme
import Disclaimer from './components/Disclaimer';
// import PersonaSection from "./components/PersonaSection";
import StreamingChat from "./components/StreamComment";
import CreatePersona from "./components/CreatePersona"; // Import Disclaimer component

function App() {
	useEffect(() => {
		hljs.highlightAll();
	}, []);

	const handleAddResponse = (response: string) => {
		// Do something with the complete response
		console.log("Complete response:", response);
	};

	return (
		<Router>
			<Navbar />
			<Box px={8}>
				<Routes>
					<Route path="/topic/:topicId/:topicMsg" element={<CommentSection />} />
					<Route path="/" element={<CommentSection />} />
					{/*<Route path="/personas" element={<PersonaSection />} />*/}
					<Route path="/create-persona" element={<CreatePersona />} />
					{/*<Route path="/persona/:personaVersionName/:personaName" element={<PersonaSection />} />*/}
					<Route path="/page" element={<CommentSection />} />
					<Route path="*" element={<NotFoundPage />} /> {/* Catch-all route for 404 page */}
					<Route path="/stream" element={<StreamingChat  handleAddResponse={handleAddResponse}/>} />

				</Routes>
			</Box>
		</Router>
	);
}

export default App;
