import { ChatClient } from './ChatClient';

export interface ChatMessage {
    role: 'system' | 'user' | 'assistant';
    content: string;
}

export const streamComment = async (
    messages: ChatMessage[],
    onContent: (content: string) => void,
    onError: (error: string) => void,
    onComplete: () => void,
    persona_id?: string
) => {
    const client = new ChatClient();
    const stream = await client.chatCompletion(messages, {
        temperature: 0.7,
        persona_id: persona_id
    });

    if (stream) {
        const reader = stream.getReader();
        const decoder = new TextDecoder();

        try {
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value);
                const lines = chunk.split('\n');
                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        const data = line.slice(6);
                        if (data === '[DONE]') continue;
                        try {
                            const parsed = JSON.parse(data);
                            if (parsed.content) {
                                onContent(parsed.content);
                            }
                        } catch (error) {
                            console.error("Error parsing JSON:", error);
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Error reading stream:", error);
            onError("Error reading stream");
        } finally {
            onComplete();
        }
    } else {
        onError('Failed to get a response from the API.');
    }
};