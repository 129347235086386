interface ChatMessage {
    role: 'system' | 'user' | 'assistant';
    content: string;
}

interface ChatCompletionOptions {
    model?: string;
    persona_id?: string;
    max_tokens?: number;
    temperature?: number;
    stop?: string[];
    frequency_penalty?: number;
    presence_penalty?: number;
    top_p?: number;
    top_k?: number;
}

export class ChatClient {
    private baseUrl: string;

    constructor(baseUrl: string = "/api") {
        this.baseUrl = baseUrl;
    }

    async chatCompletion(messages: ChatMessage[], options: ChatCompletionOptions = {}): Promise<ReadableStream<Uint8Array> | null> {
        const data = {
            messages: messages,
            options: {
                model: options.model || "llama3-8b-8192",
                persona_id: options.persona_id || "default",
            }
        };

        try {
            const response = await fetch(`${this.baseUrl}/chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return response.body;
        } catch (error) {
            console.error("An error occurred:", error);
            return null;
        }
    }
}