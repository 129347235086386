import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    Heading, VStack, Box, Text, Button, useToast, Textarea,
    Menu, MenuButton, MenuList, MenuItem,
    useStyleConfig
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Comment from './Comment';
import { TopicComments, Persona, Comment as CommentType } from '../types';
import Loading from "./Loading";
import rehypeRaw from "rehype-raw";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import ReactMarkdown from "react-markdown";
import { streamComment, ChatMessage } from '../utils/StreamingUtils';
import PersonaServices from '../services/PersonaServices';

interface Props {
    topicComments?: TopicComments;
}

const CommentsList: React.FC<Props> = ({ topicComments: initialTopicComments }) => {
    const [topicComments, setTopicComments] = useState<TopicComments | undefined>(initialTopicComments);
    const [streamedComment, setStreamedComment] = useState('');
    const [isStreaming, setIsStreaming] = useState(false);
    const [currentPersona, setCurrentPersona] = useState<Persona | null>(null);
    const [humanComment, setHumanComment] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [personas, setPersonas] = useState<Persona[]>([]);
    const toast = useToast();
    const commentRef = useRef('');
    const currentPersonaRef = useRef<Persona | null>(null);
    const pendingCommentRef = useRef<{ comment: string, isHuman: boolean, tempId?: number } | null>(null);

    const [renderKey, setRenderKey] = useState(0);
    const styles = useStyleConfig("Button", { variant: "outline" });

    useEffect(() => {
        console.log("Initial topicComments:", initialTopicComments);
        if (initialTopicComments) {
            setTopicComments(initialTopicComments);
            setIsLoading(false);
        }
    }, [initialTopicComments]);

    useEffect(() => {
        const fetchPersonas = async () => {
            try {
                const fetchedPersonas = await PersonaServices.getPersonas();
                setPersonas(fetchedPersonas);
            } catch (error) {
                console.error("Error fetching personas:", error);
                toast({
                    title: 'Error',
                    description: 'Failed to fetch personas',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'top-right',
                });
            }
        };

        fetchPersonas();
    }, [toast]);

    useEffect(() => {
        console.log("Current topicComments:", topicComments);
        setRenderKey(prevKey => prevKey + 1);
    }, [topicComments, streamedComment]);

    useEffect(() => {
        currentPersonaRef.current = currentPersona;
        if (pendingCommentRef.current) {
            const { comment, isHuman, tempId } = pendingCommentRef.current;
            postComment(comment, isHuman, tempId);
            pendingCommentRef.current = null;
        }
    }, [currentPersona]);

    const handleStreamComment = useCallback(async (persona: Persona) => {
        if (isStreaming) {
            return;
        }

        setIsStreaming(true);
        setStreamedComment('');
        commentRef.current = '';

        const messages: ChatMessage[] = [];

        let userContent = "";

        if (topicComments && topicComments.comments && topicComments.comments.length > 0) {
            const chatHistory = topicComments.comments.map(comment =>
                `${comment.version_persona_name}: ${comment.message}`
            ).join('\n');

            userContent = `---beginning of your chat history, use this as memory.---
                            Topic: ${topicComments.topic.message}
                            ${chatHistory}
                            ---end of your chat history---
                          `;
        } else {
            userContent = `Topic: ${topicComments?.topic.message || ""}`;
        }

        messages.push({ role: "user", content: userContent });

        const tempComment: CommentType = {
            id: Date.now(),
            topic_id: topicComments?.topic.id || 0,
            parent_id: 0,
            subcomment_ids: '[]',
            version_persona_name: persona.name,
            message: '',
            created_at: Date.now(),
            updated_at: Date.now()
        };

        setTopicComments(prevComments => {
            if (!prevComments) return prevComments;
            return {
                ...prevComments,
                comments: [...prevComments.comments, tempComment]
            };
        });

        try {
            await streamComment(
                messages,
                (content) => {
                    setStreamedComment(prev => prev + content);
                    commentRef.current += content;

                    setTopicComments(prevComments => {
                        if (!prevComments) return prevComments;
                        const updatedComments = prevComments.comments.map(comment =>
                            comment.id === tempComment.id
                                ? { ...comment, message: commentRef.current }
                                : comment
                        );
                        return {
                            ...prevComments,
                            comments: updatedComments
                        };
                    });
                },
                (error) => {
                    toast({
                        title: 'Error',
                        description: error,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                        position: 'top-right',
                    });
                    setIsStreaming(false);
                },
                () => {
                    setIsStreaming(false);
                    postComment(commentRef.current, false, tempComment.id);
                },
                persona.id
            );
        } catch (error) {
            console.error("Error in streamComment:", error);
            setIsStreaming(false);
            toast({
                title: 'Error',
                description: 'Failed to generate comment',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
            });
        }
    }, [topicComments, toast]);

    const postComment = async (comment: string, isHuman: boolean = false, tempId?: number) => {
        if (!comment.trim()) {
            toast({
                title: 'Error',
                description: 'Cannot post empty comment',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
            });
            return;
        }

        if (!currentPersonaRef.current && !isHuman) {
            pendingCommentRef.current = { comment, isHuman, tempId };
            return;
        }

        try {
            const response = await fetch('/api/comments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    topic_id: topicComments?.topic.id,
                    parent_id: null,
                    subcomment_ids: '[]',
                    version_persona_name: isHuman ? 'human_anonymous' : currentPersonaRef.current?.id,
                    message: comment,
                    created_at: Date.now(),
                    updated_at: Date.now(),
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to post comment');
            }

            const newComment: CommentType = await response.json();

            setTopicComments(prevComments => {
                if (!prevComments) return prevComments;
                let updatedComments;
                if (tempId) {
                    updatedComments = prevComments.comments.map(comment =>
                        comment.id === tempId ? newComment : comment
                    );
                } else {
                    updatedComments = [...prevComments.comments, newComment];
                }
                return {
                    ...prevComments,
                    comments: updatedComments
                };
            });

            setRenderKey(prevKey => prevKey + 1);
            setStreamedComment('');

            toast({
                title: 'Comment posted',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right',
            });

            if (isHuman) {
                setHumanComment('');
            }
        } catch (error) {
            console.error("Error posting comment:", error);
            toast({
                title: 'Error',
                description: 'Failed to post comment',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
            });
        }
    };

    const handleHumanCommentSubmit = () => {
        postComment(humanComment, true);
    };

    const handlePersonaSelect = (persona: Persona) => {
        setCurrentPersona(persona);
        handleStreamComment(persona);
    };

    if (isLoading) {
        return <Loading />;
    }

    if (!topicComments) {
        return <Text>No comments available.</Text>;
    }

    return (
        <VStack spacing={8} w="100%">
            {topicComments.topic.message && (
                <Heading size="lg" textAlign="left">
                    <ReactMarkdown
                        rehypePlugins={[rehypeRaw, rehypeKatex]}
                        remarkPlugins={[remarkMath]}
                    >
                        {topicComments.topic.message}
                    </ReactMarkdown>
                </Heading>
            )}
            {topicComments.comments.map(comment => (
                <Comment key={`${comment.topic_id}_${comment.id}_${renderKey}`} comment={comment} />
            ))}
            <Box w="100%">
                <Textarea
                    value={humanComment}
                    onChange={(e) => setHumanComment(e.target.value)}
                    placeholder="Enter your comment here..."
                    mb={2}
                />
                <Button onClick={handleHumanCommentSubmit} mr={2}>Post My Comment</Button>
                <Menu closeOnSelect={true}>
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        isLoading={isStreaming}
                        height="36px"
                        sx={{
                            ...styles,
                            borderRadius: "md",
                        }}
                    >
                        {isStreaming ? "Commenting..." : (currentPersona ? currentPersona.name : "Select Persona")}
                    </MenuButton>
                    <MenuList maxHeight="300px" overflowY="auto">
                        {personas.map((persona) => (
                            <MenuItem
                                key={persona.id}
                                onClick={() => handlePersonaSelect(persona)}
                                isDisabled={isStreaming}
                                sx={{
                                    padding: "0.5rem 1rem",
                                }}
                            >
                                {persona.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>
        </VStack>
    );
};

export default CommentsList;